@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Medium.eot');
    src: url('Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('Roboto-Medium.woff2') format('woff2'),
        url('Roboto-Medium.woff') format('woff'),
        url('Roboto-Medium.ttf') format('truetype'),
        url('Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Black.eot');
    src: url('Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('Roboto-Black.woff2') format('woff2'),
        url('Roboto-Black.woff') format('woff'),
        url('Roboto-Black.ttf') format('truetype'),
        url('Roboto-Black.svg#Roboto-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Bold.eot');
    src: url('Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('Roboto-Bold.woff2') format('woff2'),
        url('Roboto-Bold.woff') format('woff'),
        url('Roboto-Bold.ttf') format('truetype'),
        url('Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Regular.eot');
    src: url('Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('Roboto-Regular.woff2') format('woff2'),
        url('Roboto-Regular.woff') format('woff'),
        url('Roboto-Regular.ttf') format('truetype'),
        url('Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-Bold.eot');
    src: url('SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI-Bold.woff2') format('woff2'),
        url('SegoeUI-Bold.woff') format('woff'),
        url('SegoeUI-Bold.ttf') format('truetype'),
        url('SegoeUI-Bold.svg#SegoeUI-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI.eot');
    src: url('SegoeUI.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI.woff2') format('woff2'),
        url('SegoeUI.woff') format('woff'),
        url('SegoeUI.ttf') format('truetype'),
        url('SegoeUI.svg#SegoeUI') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-Semibold.eot');
    src: url('SegoeUI-Semibold.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI-Semibold.woff2') format('woff2'),
        url('SegoeUI-Semibold.woff') format('woff'),
        url('SegoeUI-Semibold.ttf') format('truetype'),
        url('SegoeUI-Semibold.svg#SegoeUI-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('SegoeUI-Semibold_1.eot');
    src: url('SegoeUI-Semibold_1.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI-Semibold_1.woff2') format('woff2'),
        url('SegoeUI-Semibold_1.woff') format('woff'),
        url('SegoeUI-Semibold_1.ttf') format('truetype'),
        url('SegoeUI-Semibold_1.svg#SegoeUI-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

