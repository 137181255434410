$screen-xs: 767px;

$segoi: "Segoe UI";
$roboto: "Roboto";


$primary01: #F39C26;
$primary02: #4481B4;
$primary03: #4280B4;


$text-primary01: #7E7E7E;
$text-primary-02: rgba(51, 51, 51, 0.8);

$border-primary01: rgba(51, 51, 51, 0.6);
$border-primary-02: #F4F4F4;

$dark-blue: #293462;
$white: #ffffff;
$off-white: #f5f5f7;
$light-white: rgba(255, 255, 255, 0.7);
$black: #000000;
$light-black: #333333;
$lt-orange: #F26E38;
$lt-orange-02: #FB8E0E;
$gray: #D9D9D9CC;
$red: #FF0000;
$green: #6ABE4E;
$green-1: #6BBE4D;
$green-2: #00BC40;
$blue: #0090D9;
$dk-gray: #898989;
$lt-gray: #F0F0F0;
$lt-gray-02: #EEEDEF;
$lt-gray-03: #F6F6F6;
$lt-gray-04: #D9D9D9;


$box-shadow01: 0 1px 119px rgba(0, 0, 0, 0.15);
$btn-shadow: 0 2.01389px 2.01389px rgba(0, 0, 0, 0.25);
$transition: all 0.2s ease-in-out;

body {
  font-family: $segoi;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $black;
  background: $white !important;
}

.container {
  max-width: 1170px;

  &.large {
    max-width: 1370px;
  }

  &.small {
    max-width: 1032px;
  }
}

//      ****************************  Text Element  ****************************

h1 {
  font-weight: 800;
  font-size: 52px;
  line-height: 60px;
  color: $black;
  font-family: $segoi;
  letter-spacing: -0.01em;
}

h2, .h2 {
  font-weight: 800;
  font-size: 38px;
  line-height: 56px;
  color: $black;
  font-family: $segoi;
  position: relative;
  margin: 0;
  letter-spacing: -0.01em;
  @media screen and (max-width: $screen-xs) {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
}

h3 {
  font-family: $segoi;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: $light-black;
  margin: 0;
  @media screen and (max-width: $screen-xs) {
    font-size: 16px;
    line-height: 17px;
  }

  &.light {
    font-weight: 400;
  }

  &.white {
    color: $white;
  }
}

h4 {
  font-weight: 800;
  font-size: 16px;
  line-height: 26px;
  color: $black;
}

p, .p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: $segoi;
  color: $black;
  letter-spacing: -0.01em;
  margin: 0;
  @media screen and (max-width: $screen-xs) {
    font-size: 10px;
    line-height: 13px;
  }

  &.medium {
    font-weight: 600;
  }

  &.white {
    color: $white;
  }

  &.green {
    color: #6ABE4E;
  }

  &.blue {
    color: $primary02;
  }
}

.p {
  @media screen and (max-width: $screen-xs) {
    font-size: 12px;
    line-height: 16px;
  }
}

span {
  font-family: $segoi;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $text-primary01;
  display: inline-block;
  @media screen and (max-width: $screen-xs) {
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.01em;
  }

  &.medium {
    font-weight: 600;
  }
}

a {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: $segoi;
  color: $black;
  text-decoration: none;

  &:hover {
    color: $black;
    text-decoration: none;
  }
}

label {
  // pending
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $text-primary01;
}

strong {
  font-weight: 800;
  font-size: 16px;
  line-height: 26px;
  font-family: $segoi;
  color: $black;
  @media screen and (max-width: $screen-xs) {
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
  }
}

.green {
  color: $green-1 !important;
}

//      ****************************  Button Element  ****************************
.sbtn-style {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 169px;
  height: 57px;
  padding: 12px 33px 14px;
  border-radius: 4px;
  border: 2px solid $primary01;
  text-decoration: none;
  background: $primary01;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  font-family: $segoi;
  color: $white;
  text-transform: capitalize;
  @media screen and (max-width: $screen-xs) {
    min-width: 134px;
    height: 36px;
    padding: 8px 25px 10px;
    font-weight: 600;
    font-size: 14.2133px;
    line-height: 19px;
  }
  &.disable {
    background: $dk-gray;
    border: 2px solid $dk-gray;
    pointer-events: none;
  }
}
.btn-style {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 169px;
  height: 57px;
  padding: 12px 33px 14px;
  border-radius: 4px;
  border: 2px solid $primary01;
  text-decoration: none;
  background: $primary01;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  font-family: $segoi;
  color: $white;
  text-transform: capitalize;
  @media screen and (max-width: $screen-xs) {
    min-width: 134px;
    height: 36px;
    padding: 8px 25px 10px;
    font-weight: 600;
    font-size: 14.2133px;
    line-height: 19px;
  }

  span {
    @media screen and (max-width: $screen-xs) {
      font-weight: 600;
      font-size: 11px;
      line-height: 13px;
      color: #3D3C42;
    }
  }

  &:hover {
    background: transparent;
    color: $primary01;
  }

  &.transparent {
    background: transparent;
    color: $primary01;
    border-color: $primary01;
    border-width: 1px;

    &.blue {
      border-color: $primary02;
      color: $primary02;
    }

    &.blue-bg {
      background: #F9FBFF;
    }
  }

  &.white {
    border-color: $white;
    color: $white !important;
  }

  &.black {
    border-color: $black;
    color: $black;
  }

  &.gray {
    border-color: $text-primary01;
    color: $text-primary01;
  }

  &.bg-gray {
    background: $gray;
    border: 0.4px solid rgba(51, 51, 51, 0.6);
    border-radius: 4px;
    min-width: 123px;
    height: 26px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $black;
  }

  &.light-blue {
    border-color: $primary02;
    color: $white;
    background: $primary02;
  }

  &.green {
    background: $green-1;
    border-radius: 4px;
    border: 2px solid $green-1;
  }

  &.dark-blue {
    border-color: $dark-blue;
    color: $white;
    background: $dark-blue;
  }

  &.medium {
    min-width: 145px;
    height: 50px;
    padding: 11px 12px 12px;
    font-size: 18px;
    @media screen and (max-width: $screen-xs) {
      min-width: 50px;
      height: 32px;
      padding: 6px 7px 6px;
      font-size: 10px;
      line-height: 13px;
      letter-spacing: -0.165px;
    }
  }

  &.small {
    min-width: 120px;
    height: 42px;
    padding: 11px 12px 12px;
    font-size: 15px;
    @media screen and (max-width: $screen-xs) {
      min-width: 50px;
      height: 27px;
      padding: 6px 5px 6px;
      font-size: 10px;
      line-height: 13px;
      letter-spacing: -0.165px;
    }
  }

  &.text {
    background: transparent;
    border: none;
    box-shadow: none;
    color: $primary01;
  }

  &.icon {
    display: inline-flex;
    align-items: center;
    gap: 7px;
  }

  &.rounded {
    border-radius: 20px !important;
  }

  &.full {
    min-width: 100%;
  }

  &.disable {
    background: $dk-gray;
    border: 2px solid $dk-gray;
    pointer-events: none;


  }
}

//      ****************************  Global Element  ****************************

ul {
  padding-left: 0;
  margin-bottom: 0;

  li {
    list-style: none;
    display: inline-block;
  }
}

.form {
  .form-group {
    .form-control {
      height: 44px;
      background: $white;
      border: 1px solid $border-primary01;
      box-shadow: $box-shadow01;
      border-radius: 8px;
      color: $text-primary01;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      &.pen-icon {
        background: $white url("../images/icons/edit-icon.svg") no-repeat;
        background-position: calc(100% - 10px);
      }


      &::placeholder {
        color: $text-primary01;
      }
    }

    input[type="checkbox"] {
      border: 1px solid $border-primary01;
      border-radius: 4px;
    }


  }
}


.badge {
  min-width: 60px;
  height: 20.74px;
  padding: 0.5px 2.15px 0.5px 6px;
  display: inline-flex;
  align-items: center;
  gap: 7px;
  font-weight: 600;
  font-size: 10.3704px;
  line-height: 16px;
  border-radius: 4px;

  .icon-wrapper {
    width: 5.19px;
    height: 5.19px;
    border-radius: 50%;
  }

  &.green {
    border: 1px solid $green;

    span {
      font-weight: 700;
      color: $green;
    }

    .icon-wrapper {
      background: $green;
    }
  }

  &.red {
    border: 1px solid $red;

    span {
      font-weight: 700;
      color: $red;
    }

    .icon-wrapper {
      background: $red;
    }
  }

  &.orange {
    border: 1px solid $lt-orange-02;

    span {
      font-weight: 700;
      color: $lt-orange-02;
    }

    .icon-wrapper {
      background: $lt-orange-02;
    }
  }

}
