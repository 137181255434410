@import "standard";
@import "../fonts/stylesheet.css";

body {
  padding: 0 0 35px;
}

// ********************************************   Home Page Scss   ********************************************
.banner-slider {
  .slick-slider {
    .img-wrapper {
      img {
        width: 100%;
        object-fit: cover;
      }
    }

    ul.slick-dots {
      @media screen and (max-width: $screen-xs) {
        bottom: 12px;
      }

      li {
        &.slick-active {
          button {
            &:before {
              @media screen and (max-width: $screen-xs) {
                color: $white;
              }
            }
          }
        }

        button {
          &:before {
            @media screen and (max-width: $screen-xs) {
              font-size: 12px;
              color: #c4c4c4;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.company-deals {
  @media screen and (max-width: $screen-xs) {
    padding: 15px 0 18px;
  }

  ul.deal {
    @media screen and (max-width: $screen-xs) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 28px;
    }

    li {
      @media screen and (max-width: $screen-xs) {
        text-align: center;
      }

      .img-wrapper {
        @media screen and (max-width: $screen-xs) {
          margin-bottom: 2px;
        }

        img {
          @media screen and (max-width: $screen-xs) {
            border: 0.128137px solid rgba(51, 51, 51, 0.6);
            border-radius: 50%;
            width: 44px;
            height: 44px;
            object-fit: cover;
          }
        }
      }

      .content-wrapper {
        span {
          @media screen and (max-width: $screen-xs) {
            color: $black;
          }
        }
      }
    }
  }
}

.available-products {
  &.cashback-available-products {
    @media screen and (max-width: $screen-xs) {
      padding: 24px 0 0;
    }
  }

  .filter-wrapper {
    .deal-filter {
      @media screen and (max-width: $screen-xs) {
        display: grid;
        grid-template-columns: repeat(3, auto);
        align-items: center;
        column-gap: 18px;
        justify-content: left;
        margin-bottom: 11px;
      }

      a {
        @media screen and (max-width: $screen-xs) {
          margin-bottom: 7px;
        }
      }
    }

    .product-filter {
      @media screen and (max-width: $screen-xs) {
        display: grid;
        grid-template-columns: repeat(2, auto);
        align-items: center;
        column-gap: 20px;
        justify-content: left;
        margin-bottom: 11px;
      }

      a {
        span {
          @media screen and (max-width: $screen-xs) {
            font-weight: 600;
            font-size: 11px;
            line-height: 13px;
            color: #3d3c42;
          }
        }
      }
    }

    .percent-filter {
      @media screen and (max-width: $screen-xs) {
        display: grid;
        grid-template-columns: repeat(5, auto);
        align-items: center;
        column-gap: 15px;
        justify-content: left;
        margin-bottom: 11px;
      }

      a {
        @media screen and (max-width: $screen-xs) {
          color: $light-black;
          border-color: $light-black;
          opacity: 0.5;
        }

        &.active {
          @media screen and (max-width: $screen-xs) {
            border-color: $dark-blue;
            color: $dark-blue;
            opacity: 1;
          }
        }
      }
    }
  }

  .product-wrapper {
    @media screen and (max-width: $screen-xs) {
      padding: 8px 0 0;
      border-top: 1px solid rgba(51, 51, 51, 0.6);
    }

    &.combo-product-wrapper {
      border-top: none;
      margin-top: 28px;
    }

    .available-products-card {
      @media screen and (max-width: $screen-xs) {
        background: #fffdfd;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        padding: 7px 10px 10px;
        margin-bottom: 18px;
        display: block;
      }

      .product-detail {
        @media screen and (max-width: $screen-xs) {
          display: grid;
          grid-template-columns: 75px auto;
          align-items: center;
          column-gap: 11px;
        }

        .img-wrapper {
          display: inline-block;
          @media screen and (max-width: $screen-xs) {
            border-radius: 4px;
            border: 1px solid rgba(51, 51, 51, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 75px;
            height: 75px;
            padding: 11px;
          }

          img {
            @media screen and (max-width: $screen-xs) {
              width: 100%;
              object-fit: cover;
            }
          }
        }

        .content-wrapper {
          h3 {
            @media screen and (max-width: $screen-xs) {
              margin-bottom: 0;
            }
          }

          span {
            @media screen and (max-width: $screen-xs) {
              margin: 7px 0 1px;
              font-weight: 600;
              font-size: 10px;
              line-height: 13px;
              color: rgba(51, 51, 51, 0.8);
              display: block;
            }
          }

          strong {
          }
        }
      }

      .product-offer {
        @media screen and (max-width: $screen-xs) {
          text-align: center;
        }

        img {
          @media screen and (max-width: $screen-xs) {
            display: inline-block;
            margin: 0 auto 8px;
          }
        }

        a.btn-style {
          min-width: 100%;
        }
      }
    }
    .hvcombo-products-card {
      background: $white;
      // border: 1px solid #333333;
      border-radius: 10px;
      // padding: 11px 22px;
      position: relative;
      text-align: center;
      // margin-bottom: 19px;

      img.offer {
        position: absolute;
        top: 5px;
        right: 5px;
      }

      > p {
        margin-bottom: 18px;
      }

      .comb-img {
        //display: grid;
        //grid-template-columns: repeat(3, 73px);
        display: flex;
        justify-content: center;
        column-gap: 30px;
        align-items: center;
        margin-bottom: 7px;

        .img-wrapper {
          @media screen and (max-width: $screen-xs) {
            border-radius: 4px;
            border: 1px solid rgba(51, 51, 51, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 75px;
            height: 75px;
            padding: 11px;
          }

          img {
            @media screen and (max-width: $screen-xs) {
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }

      .content-wrapper {
        h3 {
          @media screen and (max-width: $screen-xs) {
            margin: 4px 0 10px;
          }
        }

        span {
          @media screen and (max-width: $screen-xs) {
            margin: 7px 0 1px;
            font-weight: 600;
            font-size: 10px;
            line-height: 13px;
            color: rgba(51, 51, 51, 0.8);
            display: block;
          }
        }
      }
    }
    .combo-products-card {
      background: $white;
      border: 1px solid #333333;
      border-radius: 10px;
      padding: 11px 22px;
      position: relative;
      text-align: center;
      margin-bottom: 19px;

      img.offer {
        position: absolute;
        top: 5px;
        right: 5px;
      }

      > p {
        margin-bottom: 18px;
      }

      .comb-img {
        //display: grid;
        //grid-template-columns: repeat(3, 73px);
        display: flex;
        justify-content: center;
        column-gap: 30px;
        align-items: center;
        margin-bottom: 7px;

        .img-wrapper {
          @media screen and (max-width: $screen-xs) {
            border-radius: 4px;
            border: 1px solid rgba(51, 51, 51, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 75px;
            height: 75px;
            padding: 11px;
          }

          img {
            @media screen and (max-width: $screen-xs) {
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }

      .content-wrapper {
        h3 {
          @media screen and (max-width: $screen-xs) {
            margin: 4px 0 10px;
          }
        }

        span {
          @media screen and (max-width: $screen-xs) {
            margin: 7px 0 1px;
            font-weight: 600;
            font-size: 10px;
            line-height: 13px;
            color: rgba(51, 51, 51, 0.8);
            display: block;
          }
        }
      }
    }
  }
}

// ********************************************   TDS Report Page Scss   ********************************************
.tds-reports {
  .filter-btn {
    @media screen and (max-width: $screen-xs) {
      margin: 32px 0 20px;
    }
  }

  .tds-reports-wrapper {
    .tds-reports-card {
      padding: 24px 0 21px;
      border-top: 1px solid $border-primary01;

      .tds-reports-detail {
        @media screen and (max-width: $screen-xs) {
          display: grid;
          grid-template-columns: 75px auto;
          column-gap: 25px;
          align-items: center;
        }

        .img-wrapper {
          @media screen and (max-width: $screen-xs) {
            border: 1px solid $border-primary01;
            width: 75px;
            height: 75px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .content-wrapper {
          h3 {
            @media screen and (max-width: $screen-xs) {
              margin-bottom: 11px;
            }
          }

          p {
            @media screen and (max-width: $screen-xs) {
              margin-bottom: 0;
            }
          }
        }
      }

      .btn-wrapper {
        a.btn-style {
          @media screen and (max-width: $screen-xs) {
            min-width: 67px;
          }

          &:first-of-type {
            @media screen and (max-width: $screen-xs) {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

.recent-order {
  .sort-filter {
    @media screen and (max-width: $screen-xs) {
      padding: 10px 0 20px;
    }
  }

  .recent-order-wrapper {
    padding: 30px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    > h3 {
      @media screen and (max-width: $screen-xs) {
        margin-bottom: 0;
      }
    }

    .order-history-link {
      @media screen and (max-width: $screen-xs) {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.165px;
        text-decoration-line: underline;
        padding: 25px 0 0;
        display: block;
      }
    }
  }
}

.all-topics-wrapper {
  > h3 {
    @media screen and (max-width: $screen-xs) {
      margin-bottom: 15px;
    }
  }

  .accordion-anchor {
    display: flex;
    align-items: center;
    gap: 11px;
    padding: 15px 4px;
    border-radius: 0;
    background: $white;
    position: relative;

    &:after {
      content: url("../images/icons/product-triangle-arrow.svg");
      position: absolute;
      right: 6px;
    }
  }

  .accordion {
    .accordion-item {
      border: none;
      border-bottom: 1px solid #dee2e6;

      &:focus {
        box-shadow: none;
        outline: none;
        background: $white;
      }

      .accordion-header {
        .accordion-button {
          display: flex;
          align-items: center;
          gap: 11px;
          padding: 15px 4px;
          border-radius: 0;
          background: $white;

          &.collapsed {
            &:after {
              transform: rotate(0);
            }
          }

          &:after {
            background-image: url("../images/icons/product-triangle-arrow.svg");
            width: 12px;
            height: 12px;
            background-size: contain;
            background-position: center;
            transform: rotate(90deg);
          }

          &:focus {
            box-shadow: none;
            outline: none;
            background: $white;
          }

          h3 {
            margin-bottom: 0;
          }
        }
      }

      .accordion-collapse {
        .accordion-body {
          background: $white;
          padding: 10px 20px 10px 27px;

          .form {
            .form-group {
              margin-bottom: 15px;
              &.submit {
                text-align: center;
              }

              .form-control {
                background: $white;
                border: 0.4px solid rgba(51, 51, 51, 0.5);
                border-radius: 6px;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: -0.01em;
                color: rgba(0, 0, 0, 0.5);

                &::placeholder {
                  color: rgba(0, 0, 0, 0.5);
                }
              }

              textarea {
                padding: 14px 21px;
                height: 93px;
                resize: none;
              }

              input[type="submit"] {
                padding: 6px 25px 10px;
              }
            }
          }
        }
      }
    }
  }

  &.order-details-accordion-wrapper {
    .accordion {
      .accordion-item {
        .accordion-header {
          .accordion-button {
            width: 135px;
            border: none;
          }
        }

        .accordion-body {
          padding: 5px 8px 0 8px;
          border: 1px solid rgba(51, 51, 51, 0.1);
          box-shadow: $btn-shadow;

          .order-details-heading,
          .order-details-body {
            ul {
              display: grid;
              grid-template-columns: 15% 10% 30% 25% 20%;
              padding: 8px 0;
              border-bottom: 1px solid rgba(51, 51, 51, 0.1);
              text-align: center;

              li {
                h3 {
                  font-weight: 700;
                }

                p {
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 19px;
                  color: #646464;
                }
              }
            }

            .item-name {
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              color: $lt-orange;
              margin-left: 8px;
            }
          }

          .order-details-body {
            padding-top: 8px;

            ul {
              border: none;
            }
          }
        }
      }
    }
  }
}

// ********************************************  Order Description Page Scss   ********************************************
.order-details-wrapper {
  border: 1px solid rgba(51, 51, 51, 0.1);
  box-shadow: $btn-shadow;
  padding: 15px 10px;
  @media screen and (max-width: $screen-xs) {
    margin-top: 25px;
  }

  .order-details-slot {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;

      &.success {
        color: green;
      }
    }
  }
}

.invoice-wrapper {
  margin: 64px 0;

  .btn-style {
    margin: 17px 0;
    min-width: 125px;
    height: 36px;
    padding: 10px 0 10px;
    font-weight: 600;
    font-size: 12.2133px;
  }
}

// ********************************************  Ticket History Page Scss   ********************************************
.order-ticket-wrapper {
  .recent-invoice-ticket {
    position: relative;

    &:before {
      content: url("../images/icons/product-triangle-arrow.svg");
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }

    .img-wrapper {
      width: 20px;
      height: 20px;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }

    .content-wrapper {
      //display: grid;
      //grid-template-columns:60% auto;
      //align-items: start;
      padding: 11px 0 17px;
      position: relative;
      border-top: 0.4px solid rgba(51, 51, 51, 0.6);

      .left-content {
        padding-left: 30px;

        h3 {
          font-size: 11px;
          line-height: 15px;
          color: $light-black;
          margin-bottom: 3px;
          position: relative;

          &:before {
            content: url("../images/icons/tiket-invoice.svg");
            position: absolute;
            top: 2px;
            left: -28px;
          }
        }

        p {
          font-size: 10px;
          line-height: 13px;
          color: $text-primary-02;
          margin-bottom: 3px;
        }

        .btn-style.icon {
          min-width: 50px;
          height: 19px;
          font-weight: 600;
          font-size: 8.31984px;
          line-height: 11px;
          padding: 4px;

          img {
            width: 12px;
            height: 12px;
          }
        }
      }

      .right-content {
        p {
          font-size: 8px;
          line-height: 11px;
          color: $text-primary-02;
          margin-bottom: 14px;
        }

        .btn-style {
          margin-right: 12px;
          min-width: 74px;
          height: 20px;
          font-weight: 600;
          font-size: 8.31984px;
          line-height: 11px;
          color: $white;
          background: $blue;
          border-radius: 3.56565px;
          border: none;
          padding: 4px 2px;
          display: inline-block;
        }
      }
    }
  }
}

.ticket-steps {
  ul.steps {
    padding: 26px 0;

    li {
      display: grid;
      grid-template-columns: 50px auto;
      gap: 53px;
      position: relative;
      padding-bottom: 54px;

      &.active {
        &:before {
          display: block;
        }

        &:after {
          display: block;
        }
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }

      &:before {
        content: url("../images/icons/step-check-icon.svg");
        position: absolute;
        left: 63px;
        top: 0;
        z-index: 1;
        display: none;
      }

      &:after {
        content: "";
        height: calc(100% - 15px);
        width: 1px;
        background: $primary01;
        position: absolute;
        left: 72px;
        top: calc(50% + 8px);
        transform: translateY(-50%);
        display: none;
      }

      p {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.165px;
      }
    }
  }
}

// ********************************************   Wallet Page Scss   ********************************************
.wallet {
  .waller-card-wrapper {
    padding: 22px 0;

    .waller-card {
      background: $white;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      padding: 18px 13px 11px 22px;
      margin-bottom: 22px;

      .wallet-detail {
        display: grid;
        grid-template-columns: 36px auto;
        align-items: center;
        gap: 18px;

        .content-wrapper {
          h3 {
            margin-bottom: 0;
          }

          strong {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: $light-black;
            letter-spacing: -0.01em;
          }
        }
      }

      .price-wrapper {
        margin-top: 24px;

        h3 {
          margin-bottom: 9px;
        }
      }

      p {
        margin: 18px 0 0;
        font-size: 14px;
        line-height: 19px;
      }

      a.btn-style {
        min-width: 100%;
        width: 100%;
        box-shadow: 0 2.01389px 2.01389px rgba(0, 0, 0, 0.25);
      }

      > a {
        font-size: 14px;
        line-height: 19px;
        text-decoration: underline;
        display: block;
        text-align: right;
        margin: 8px 0 0;
      }
    }
  }
}

// ********************************************   Wallet-Cash Page Scss   ********************************************
.wallet-cash-wrapper {
  background: $lt-gray;

  .product-filter {
    padding: 13px 0 15px 0;

    .btn-style {
      margin-right: 20.32px;
      border: 0.4px solid rgba(51, 51, 51, 0.5);
      border-radius: 8px;
    }
  }

  .month-card-wrapper {
    .card-heading {
      background: #fafafa;
      padding: 7px 0;
    }

    .card-body {
      padding: 22px 0;
      background: $white;

      .row {
        &:not(:last-of-type) {
          margin-bottom: 22px;
        }
      }

      .right-content-wrapper {
        display: flex;
        align-items: center;
        column-gap: 11px;

        .img-wrapper {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 0.4px solid rgba(51, 51, 51, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 36px;
            height: 36px;
          }
        }

        .content-wrapper {
          p {
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
          }
        }
      }

      .left-content-wrapper {
        display: flex;
        align-items: center;
        column-gap: 11px;
        justify-content: flex-end;

        .content-wrapper {
          h3 {
            color: #6bbe4d;
            font-size: 16px;
            line-height: 21px;
          }
        }

        .img-wrapper {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: rgba(0, 188, 64, 0.24);
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      .right-content-wrapper {
        .content-wrapper {
          span.order-id {
            font-size: 10px;
            color: black;
            font-weight: 700;
          }
        }
      }
    }
  }
}

// ********************************************   Transactions Page Scss   ********************************************
.transactions-wrapper {
  //background: #D9D9D9;
  padding: 7px 5px 0 0;

  .transactions-inner-wrapper {
    border-radius: 5px 5px 0 0;
    background: $white;

    .cash-wrapper {
      padding: 10px 0 14px 0;
      border-bottom: 1px solid $black;

      .redeem-wrapper {
        .current-balance {
          display: flex;
          align-items: center;
          column-gap: 8px;
          margin-bottom: 8px;

          .icon-wrapper {
            width: 30px;
            height: 30px;

            img {
              width: 100%;
            }
          }

          .balance {
            p {
              font-size: 14px;
              line-height: 19px;
            }

            h2 {
              font-weight: 700;
              font-size: 16px;
              line-height: 19px;
            }
          }
        }

        .btn-style {
          min-width: 88px;
          padding: 3px 10px 5px;
          height: 32px;
        }
      }

      .total-wrapper {
        h3 {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: $black;
        }

        .product-filter {
          margin-top: 9px;
          display: flex;
          gap: 9px;
          align-items: center;
          justify-content: flex-end;

          .btn-style {
            font-size: 9px;
            gap: 3px;
            justify-content: space-between;
            height: 19.29px;
            border: 0.2px solid $border-primary-02;

            img {
              width: 12.17px;
              height: 9.17px;
            }

            span {
              font-size: 9px;
              font-weight: 600;
            }

            &:first-of-type {
              min-width: 93px;
            }
          }
        }
      }
    }

    .transactions-history-wrapper {
      .transaction-card {
        border-top: 0.2px solid $border-primary-02;
        padding: 12px 10px 0;

        .left-content {
          h3 {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 3px;
          }
        }

        .right-content {
          p {
            font-size: 14px;
            line-height: 19px;
          }

          span {
            color: $primary01;
            font-weight: 600;
            font-size: 11px;
          }
        }
      }
    }
  }
}

// ********************************************   Transactions Page Scss   ********************************************
.transactions-redeem-wrapper {
  padding-top: 17px;

  .btn-wrapper {
    margin: 15px auto 21px auto;

    .btn-style.disable {
      &.active {
        border: 2px solid $primary01;
        background: $primary01;
      }
    }
  }

  .form {
    margin: 0 auto;

    .form-group {
      &:not(:last-of-type) {
        margin-bottom: 15px;
      }

      .form-control {
        border: 1px solid #e3e3e3;
        background: $white;
      }

      input[type="radio"] {
        display: none;
      }

      label {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.165px;
        color: $light-black;
        margin-bottom: 7px;
        display: flex;
        align-items: center;
        column-gap: 9px;

        p {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.165px;
          color: $light-black;
        }

        span {
          border-radius: 50%;
          border: 1px solid rgba($light-black, 0.6);
          width: 12px;
          height: 12px;
          position: relative;

          img[alt="radio-Icon"] {
            width: 8.57px;
            height: 8.57px;
            display: none;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }

      input:checked + label {
        span {
          img[alt="radio-Icon"] {
            display: inline-block;
          }
        }
      }
    }
  }

  p {
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.165px;
  }
}

// ********************************************   Cashback Deals Page Scss   ******************************************

.img-wrapper-container {
  display: flex;
}

// .img-wrapper {
//   width: 50%;
//   box-sizing: border-box;
//   padding: 0 5px;

// }
.loyalty-deals-wrapper {
  h1 {
    margin: 4px 0 0 0;
    @media screen and (max-width: $screen-xs) {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      letter-spacing: -0.01em;
    }
  }

  .floating-wrapper {
    position: absolute;
    right: 10px;
    width: 61px;
    height: 41px;
    text-align: center;
    top: 11px;

    img {
      width: 18px;
      height: 18px;
      margin: 0 auto 4px auto;
    }

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.01em;
      color: $black;
    }
  }

  .loyalty-deals-banner {
    padding-top: 31px;
    position: relative;
  }

  .day-steps-wrapper {
    margin-top: 31px;

    ul {
      text-align: center;
      margin-bottom: 29px;

      li {
        text-transform: capitalize;

        &:not(:last-of-type) {
          margin-right: 40px;

          a {
            .img-wrapper {
              &:before {
                content: "";
                height: 1px;
                left: 25px;
                width: 47px;
                border-bottom: 1px dotted black;
                position: absolute;
                top: 50%;
              }
            }
          }
        }

        a {
          text-align: center;
          display: inline-block;

          .img-wrapper {
            width: 24px;
            height: 24px;
            border: 1px solid $black;
            border-radius: 50%;
            position: relative;

            &:after {
              content: "";
              position: absolute;
              width: 16px;
              height: 16px;
              background: $primary01;
              border-radius: 50%;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }

            &.icon {
              width: 24px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              background: transparent;

              &:after {
                content: unset;
              }
            }

            img {
              width: 100%;
            }
          }

          p {
            font-size: 14px;
            line-height: 19px;
            letter-spacing: -0.01em;
            color: $black;
            margin-top: 7px;
          }

          &.active {
            .img-wrapper {
              width: 24px;
              height: 24px;
              background: $primary01
                url("../images/icons/simple-check-icon.svg") no-repeat 55%;
              outline: none;
              background-size: 60%;
              border: none;

              &:after {
                content: unset;
              }

              &:before {
                background: $black;
              }
            }

            p {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .loyalty-deals-image-wrapper {
    max-width: 375px;
    height: 381px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .testing-wrapper {
    border-top: 16px solid $gray;
    border-bottom: 16px solid $gray;
    padding: 32px 0 13px 0;
    position: relative;
    max-width: 425px;
  }
}

// ********************************************   Reward Details Page Scss   ******************************************
.reward-details-wrapper {
  background: $lt-gray-02;
  padding-top: 18px;

  .reward-banner-card {
    background: $white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 19px 31px 12px 31px;
    position: relative;
    z-index: 9;

    h1 {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: $black;
    }

    p {
      font-weight: 350;
      font-size: 14px;
      line-height: 19px;
      color: $border-primary01;

      span {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.01em;
        color: $black;
      }
    }

    a {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-decoration-line: underline;
      color: $primary01;
      margin-top: 8px;
    }
  }

  .milestone-main-wrapper {
    display: grid;
    grid-template-columns: 40px auto;
    column-gap: 23px;

    .milestone-steps-wrapper {
      width: 20px;
      height: 100%;
      background: $white;
      margin: 0 auto;
      position: relative;
      z-index: 1;

      &:after {
        content: "";
        position: absolute;
        background: $green-2;
        border-radius: 50px 50px 0 0;
        width: 100%;
        bottom: 0;
        height: 65%;
      }

      span {
        position: absolute;
        top: 7.5%;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: url("../images/icons/flag-icon.svg") no-repeat center;
        background-size: 100%;
        left: -50%;
        right: -50%;
        margin: 0 auto;
        z-index: 9;

        &.active {
          width: 36px;
          height: 36px;
          background: $green-2 url("../images/icons/simple-check-icon.svg")
            no-repeat center;
          border: 0.5px solid $white;
          background-size: 65%;
        }

        &:nth-of-type(2) {
          top: 42%;
        }

        &:nth-of-type(3) {
          top: 74.5%;
        }
      }
    }

    .milestone-card-wrapper {
      margin-top: 27px;

      .milestone-card {
        position: relative;
        background: $white;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        display: flex;
        align-items: flex-start;
        column-gap: 15px;
        padding: 20px 13px;

        &:not(:last-of-type) {
          margin-bottom: 33px;
        }

        &:before {
          content: url("../images/icons/product-triangle-arrow.svg");
          position: absolute;
          top: 20px;
          right: 20px;
        }

        &:after {
          content: "";
          position: absolute;
          width: 21px;
          height: 15px;
          background: $white;
          left: -18px;
          top: 20px;
          clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
          transform: rotate(-90deg);
        }

        .img-wrapper {
          width: 54px;
          height: 64px;
          border-radius: 4px;
          background: rgba($primary03, 0.8);
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            background: $primary03;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            h3 {
              font-weight: 400;
            }
          }
        }

        .content-wrapper {
          span {
            font-weight: 600;
            color: $text-primary-02;
          }

          h3 {
            color: $light-black;
            line-height: 21px;
          }

          p {
            font-size: 14px;
            line-height: 19px;
            color: $light-black;
          }

          .action-wrapper {
            display: flex;
            align-items: flex-start;
            column-gap: 11px;

            .btn-style {
              padding: 2px 10px 3px;
              min-width: 74px;
              height: 26px;
              margin-top: 12px;
              font-weight: 400;
            }

            > h3 {
              color: $primary01;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}

// ********************************************   Reward Details Page Scss   ******************************************

.mile-stone-wrapper {
  background: $lt-gray-02;
  height: 530px;
  position: relative;
  padding: 25px;

  .milestone-card-wrapper {
    background: $white;
    border-radius: 10px;
    padding: 26px 33px 44px 33px;
    max-width: 332px;
    position: fixed;
    top: 81px;
    left: 0;
    right: 0;
    margin: 0 auto 30px auto;
    z-index: 999;

    h3 {
      font-size: 20px;
      line-height: 27px;
      color: $black;
    }

    > .btn-style {
      margin: 24px 0 39px 0;
      font-size: 16px;
      font-weight: 400;
    }

    ul {
      li {
        p {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 10px;
        }
      }
    }
  }

  > .btn-style {
    position: absolute;
    bottom: 36px;
    left: 0;
    right: 0;
    margin: 0 25px;
    height: 55px;
    font-size: 18px;
    line-height: 24px;
  }
}

// ********************************************   Gift Card Page Scss   ******************************************

.gift-cards-wrapper {
  min-height: calc(100vh - 105px);

  .gift-card-banner {
    border-bottom: 0.6px solid $light-black;
    padding: 14px 0 20px 0;
    display: flex;
    align-items: center;
    column-gap: 12px;

    .img-wrapper {
      width: 42px;
      height: 42px;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .content-wrapper {
      h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        color: $light-black;
      }

      strong {
        color: $light-black;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
      }
    }
  }

  .coin-gift-card {
    padding: 29px 0 26px 0;
    display: grid;
    grid-template-columns: 26% 44% 21%;
    align-items: center;
    column-gap: 12px;
    border-bottom: 0.6px solid $light-black;

    .img-wrapper {
      width: 84px;
      height: 80px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $lt-gray-03;

      img {
        width: 85%;
      }
    }

    .content-wrapper {
      h3 {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: $text-primary-02;

        strong {
          color: $black;
          font-weight: 700;
        }
      }
    }

    .btn-wrapper {
      .btn-style {
        min-width: 70px;
        padding: 5px;
      }
    }

    .gift-btn-wrapper {
      .btn-style {
        min-width: 72px;
        height: 22px;
        padding: 2px 5px;

        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }
    }

    &.my-gift-card {
      border: none;
    }
  }
}

// ********************************************   Gift Description Page Scss   ************************************

.gift-description-wrapper {
  min-height: calc(100vh - 105px);
  padding: 29px 0 22px;

  .gift-description-banner {
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      color: $text-primary-02;
    }

    .img-wrapper {
      width: 142px;
      height: 135px;
      border-radius: 8px;
      margin: 43px auto 12px auto;
      background: $lt-gray-03;

      img {
        width: 95%;
      }
    }

    p {
      font-weight: 600;
      color: rgba($light-black, 0.8);
      font-size: 12px;
      line-height: 16px;

      span {
        font-size: 14px;
        line-height: 19px;
        color: $light-black;
      }
    }
  }

  .year-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 56px 0 15px 0;
    padding-bottom: 19px;
    border-bottom: 0.5px solid rgba($light-black, 0.8);

    .left-content {
      p {
        font-weight: 600;
        color: rgba($light-black, 0.8);
        font-size: 12px;
        line-height: 16px;

        span {
          font-size: 14px;
          line-height: 19px;
          color: $light-black;
        }
      }
    }

    .right-content {
      display: inline-flex;
      align-items: center;
      column-gap: 7px;

      .btn-style {
        padding: 5px;
        min-width: 70px;
      }

      p {
        font-size: 12px;
        line-height: 16px;
        color: rgba($light-black, 0.8);
      }
    }
  }

  .about-wrapper {
    margin-bottom: 91px;

    span {
      font-size: 13px;
      line-height: 17px;
      color: rgba($light-black, 0.8);
    }

    p {
      font-size: 16px;
    }
  }

  .redeem-wrapper {
    a {
      font-size: 16px;
      line-height: 21px;
      color: $primary01;
      text-decoration: underline;
      margin-bottom: 14px;
      display: block;
    }

    .btn-wrapper {
      .btn-style {
        margin: 23px auto 32px auto;
        min-width: 286px;
        height: 40px;
        color: white;

        &:hover {
          color: $primary01;
          background: transparent;
        }
      }
    }
  }
}

// ********************************************   Cashback Page Scss   ******************************************
.schema-product {
  &.combo-schema-product {
    .combo-img-wrapper {
      margin: 19px 0 63px;
      display: grid;
      grid-template-columns: repeat(2, 122px);
      justify-content: center;
      align-items: self-start;
      column-gap: 60px;

      .combo-img {
        .product-img {
          margin: auto;
        }

        ul {
          margin: 19px 0 0;

          li {
            display: block;

            &:not(:last-of-type) {
              margin: 0 0 10px;
            }
          }
        }
      }
    }
  }

  .product-img {
    background: $white;
    border: 0.4px solid rgba(51, 51, 51, 0.6);
    border-radius: 4px;
    width: 121px;
    height: 121px;
    margin: 21px auto 33px;
    padding: 6px 10px;

    img {
      max-width: 98px;
      object-fit: cover;
    }
  }
  .cproduct-img {
    background: $white;
    // border: 0.4px solid rgba(51, 51, 51, 0.6);
    border-radius: 4px;
    width: 121px;
    height: 121px;
    margin: 21px auto 33px;
    padding: 6px 10px;

    img {
      max-width: 98px;
      object-fit: cover;
    }
  }

  ul.product-price {
    margin-bottom: 24px;

    li {
      display: inline-block;

      &:not(:last-of-type) {
        margin: 0 35px 0 0;
      }

      h3 {
        margin-bottom: 0;
      }
    }
  }

  .variant-wrapper {
    margin-bottom: 41px;

    p {
      margin-bottom: 6px;
    }

    ul {
      margin-bottom: 0;

      li {
        display: inline-block;

        &:not(:last-of-type) {
          margin: 0 10px 0 0;
        }
      }
    }
  }
}

.schema-offer-track {
  .container {
    > h3 {
      margin-bottom: 16px;
    }
  }

  ul.offer-track {
    li {
      display: grid;
      grid-template-columns: 31px auto;
      column-gap: 11px;
      align-items: center;
      position: relative;
      min-height: 56px;

      &:not(:last-of-type) {
        margin-bottom: 19px;

        &:before {
          content: "";
          border: 1px dashed rgba(51, 51, 51, 0.3);
          width: 1px;
          height: calc(100% - 10px);
          position: absolute;
          left: 14px;
          top: 43px;
        }
      }

      span {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.01em;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.3);
      }

      .content-wrapper {
        background: rgba(217, 217, 217, 0.6);
        border-radius: 4px;
        padding: 10px 16px;

        h3 {
          margin-bottom: 3px;
          color: $light-black;
        }

        p {
          margin-bottom: 0;
          color: rgba(51, 51, 51, 0.8);
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.01em;
        }
      }
    }
  }

  .terms-condition {
    margin-top: 43px;

    .accordion {
      .accordion-item {
        border: none !important;
        border-bottom: 1px solid #dee2e6;

        &:focus {
          box-shadow: none;
          outline: none;
          background: $white;
        }

        .accordion-header {
          max-width: 170px;
          margin: auto;
          border-radius: 30px;
          height: 32px;

          .accordion-button {
            display: flex;
            align-items: center;
            gap: 11px;
            padding: 15px 8px;
            background: $white;
            border-radius: 30px;
            height: 32px;
            background: #ffffff;
            border: 0.5px solid rgba(51, 51, 51, 0.5);

            &:after {
              background-image: url("../images/icons/down-triangle-icon.svg");
              width: 15px;
              height: 15px;
              background-size: contain;
              background-position: center;
            }

            &:focus {
              box-shadow: none;
              outline: none;
              background: $white;
            }

            p {
              margin-bottom: 0;
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
            }
          }
        }

        .accordion-collapse {
          .accordion-body {
            background: $white;
            padding: 22px 20px 10px 27px;

            ul.conditions {
              padding: 0 0 40px;
              position: relative;
              margin-bottom: 30px;

              &:before {
                content: "";
                background: $black;
                width: 140px;
                height: 2px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
              }

              li {
                position: relative;
                padding-left: 15px;

                &:before {
                  content: "";
                  background: $black;
                  width: 4px;
                  height: 4px;
                  position: absolute;
                  left: 0;
                  top: 11px;
                  border-radius: 50%;
                }

                &:not(:last-of-type) {
                  margin-bottom: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  a.order-btn {
    height: 55px;
    margin: 30px 0 30px;
  }
}

// ********************************************   Notifications Page Scss   ******************************************
.notifications {
  padding: 24px 0;
  display: inline-block;

  .notifications-wrapper {
    .nav-pills {
      display: grid;
      grid-template-columns: 134px 134px;
      align-items: center;
      column-gap: 25px;
      margin: 0 0 28px;

      .nav-item {
        a {
          display: inline-block;
          background: rgba(243, 157, 38, 0.4);
          color: $primary01;
          text-align: center;

          &.active {
            background: $primary01;
            color: $white;
          }
        }
      }
    }

    .tab-content {
      ul.notification-card {
        li {
          background: $white;
          border: 1px solid rgba(51, 51, 51, 0.2);
          border-radius: 5.37263px;
          padding: 10px 15px 12px 30px;
          width: 100%;
          position: relative;
          margin: 0 0 16px;

          &.active {
            background: rgba(243, 156, 38, 0.2);
            border: none;
          }

          &:before {
            content: "";
            background: $primary01;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            position: absolute;
            left: 14px;
            top: 16px;
          }

          .content-wrapper {
            p.p {
              font-weight: 700;
              margin: 0;
            }

            p {
              margin: 7px 0 3px;
            }

            span {
              color: #1f2937;
            }
          }
        }
      }
    }
  }
}

// ********************************************   Scratch Card Page Scss   ******************************************

.scratch-card-sec {
  padding: 28px 0;

  .scratch-card-wrapper {
    .scratch-card {
      background: $primary01;
      border-radius: 8px;
      padding: 10px 14px;
      text-align: center;
      margin-bottom: 14px;

      h3 {
        margin-bottom: 11px;
      }

      p {
        margin: 13px 0 0;
      }
    }
  }
}

// ********************************************   Refer Earn Page Scss   ******************************************

.refer-earn-wrapper {
  .refer-banner {
    background: linear-gradient(
      69.88deg,
      rgba(243, 156, 38, 0.8) -9.41%,
      rgba(243, 156, 38, 0) 102.05%
    );
    padding: 29px 0 23px 0;

    .content-wrapper {
      h3 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 7px;
      }

      p {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .img-wrapper {
      width: 53px;
      height: 53px;

      img {
        width: 100%;
      }
    }

    .refer-text {
      margin: 25px 0 13px 0;

      p {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        position: relative;
        padding: 0 12px;

        &:after {
          content: "";
          position: absolute;
          border-bottom: 1px solid rgba($light-black, 0.8);
          top: 10px;
          left: 0;
          width: 41%;
          z-index: 2;
        }

        &:before {
          content: "";
          position: absolute;
          border-bottom: 1px solid rgba($light-black, 0.8);
          top: 10px;
          right: 0;
          width: 41%;
          z-index: 2;
        }
      }
    }

    .social-links {
      .img-wrapper {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        img {
          width: 31px;
          height: 31px;
        }
      }
    }
  }

  .how-it-works {
    margin: 63px 0 50px 0;

    h3 {
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      padding-bottom: 13px;
      border-bottom: 1px dotted rgba($light-black, 0.5);
    }

    ul {
      margin: 24px 0 0 30px;

      li {
        display: flex;
        align-items: center;
        column-gap: 17px;

        &:not(:last-of-type) {
          margin-bottom: 24px;

          span {
            &:after {
              content: "";
              position: absolute;
              width: 1px;
              height: 30px;
              bottom: -28px;
              left: 50%;
              background: #e1e1e1;
            }
          }
        }

        span {
          width: 24px;
          height: 24px;
          background: rgba($lt-gray-04, 0.8);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $black;
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
          padding: 10px;
          position: relative;
        }

        p {
          font-size: 12px;
          line-height: 16px;
          color: $black;
        }
      }
    }
  }
}

// ********************************************   how to Redeem & Account Issues Page Scss   ******************************************
.redeem-steps {
  .steps {
    margin: 34px 0 115px 0;
    padding-left: 15px;

    li {
      &:not(:last-of-type) {
        margin-bottom: 14px;
      }

      p {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }

  .btn-wrapper {
    .btn-style {
      min-width: 125px;
      height: 36px;
    }
  }
}

.account-issues {
  margin-top: 46px;

  .account-anchor {
    display: flex;
    align-items: center;
    gap: 18px;
    padding: 15px 4px;
    border-radius: 0;
    background: $white;
    position: relative;

    &:not(:last-of-type) {
      border-bottom: 1px solid rgba($light-black, 0.8);
    }

    &:after {
      content: url("../images/icons/arrow-black-right.svg");
      position: absolute;
      right: 6px;
    }

    p {
      font-size: 16px;
      line-height: 21px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}

// ********************************************   Global Scss   ********************************************
header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;

  &.coin-header {
    min-height: 153px;
    padding-top: 17px;

    .btn-style {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &.inner-header {
    @media screen and (max-width: $screen-xs) {
      padding: 14px 0;
    }

    &.transparent-header {
      background: $white;

      .top-header {
        .page-name {
          img {
            @media screen and (max-width: $screen-xs) {
              padding: 2px 0 0;
            }
          }

          h3 {
            @media screen and (max-width: $screen-xs) {
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
              letter-spacing: -0.165px;
              color: $light-black;
            }
          }
        }

        .label {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          text-align: right;
          letter-spacing: -0.165px;
          color: #f26e38;
          margin: 0;
        }
      }
    }

    .top-header {
      @media screen and (max-width: $screen-xs) {
        margin: 0;
      }

      .page-name {
        @media screen and (max-width: $screen-xs) {
          gap: 10px;
        }

        img {
          @media screen and (max-width: $screen-xs) {
            padding: 4px 0 0;
          }
        }

        h3 {
          @media screen and (max-width: $screen-xs) {
            font-weight: 600;
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }

    &.gift-card-header {
      .top-header {
        .label {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          text-decoration-line: underline;
          color: $white;
        }
      }
    }
  }

  &.brand-header {
    .content-wrapper {
      h3 {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.01em;
        margin-bottom: 7px;
        padding: 0;
      }

      p {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: $screen-xs) {
    background: $primary01;
    padding: 10px 0 9px;
  }

  .top-header {
    @media screen and (max-width: $screen-xs) {
      margin-bottom: 10px;
    }

    .page-name {
      @media screen and (max-width: $screen-xs) {
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 18px;
      }

      h3 {
        @media screen and (max-width: $screen-xs) {
          margin-bottom: 0;
        }
      }
    }

    .btn-wrapper {
      a {
        &:last-of-type {
          @media screen and (max-width: $screen-xs) {
            margin: 0 0 0 8px;
          }
        }
      }
    }
  }

  .header-wrapper {
    .earned-card {
      @media screen and (max-width: $screen-xs) {
        text-align: center;
        margin-bottom: 8px;
      }

      a {
        @media screen and (max-width: $screen-xs) {
          display: inline-flex;
          align-items: center;
          gap: 5px;
          background: $white;
          border-radius: 10px;
          padding: 5px 15px 5px 6px;
          min-width: 50px;
          position: relative;
        }

        &:after {
          content: url("../images/icons/right-triangle-arrow.svg");
          position: absolute;
          top: 0;
          right: 7px;
        }

        span {
          @media screen and (max-width: $screen-xs) {
            font-weight: 600;
            font-size: 10px;
            line-height: 13px;
            letter-spacing: -0.01em;
            color: $light-black;
          }
        }

        img {
          @media screen and (max-width: $screen-xs) {
            min-width: 13px;
            max-height: 13px;
          }
        }
      }

      > p {
        margin: 3px 0 0;
      }
    }

    .btn-wrapper {
      @media screen and (max-width: $screen-xs) {
        display: flex;
        align-items: center;
        justify-content: right;
        gap: 10px;
      }

      a.btn-style {
        @media screen and (max-width: $screen-xs) {
          border-radius: 6px;
          padding: 6px 14px 6px;
        }
      }
    }

    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      letter-spacing: -0.165px;
      padding-left: 25px;

      strong {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.165px;
        color: $white;
      }
    }
  }
}

footer {
  @media screen and (max-width: $screen-xs) {
    background: $primary01;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px 0 6px;
    z-index: 99999;
  }

  p {
    margin-bottom: 0;
    @media screen and (max-width: $screen-xs) {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }
  }
}

.product-card {
  @media screen and (max-width: $screen-xs) {
    display: grid;
    grid-template-columns: 62px auto;
    column-gap: 5px;
    align-items: center;
    padding: 24px 0 21px;
    position: relative;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    &:last-of-type {
      border-bottom: none;
    }
  }

  &:before {
    content: url("../images/icons/product-triangle-arrow.svg");
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  .img-wrapper {
    @media screen and (max-width: $screen-xs) {
      border: 1px solid $border-primary01;
      width: 60px;
      height: 60px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      @media screen and (max-width: $screen-xs) {
        max-width: 50px;
      }
    }
  }

  .content-wrapper {
    h3 {
      @media screen and (max-width: $screen-xs) {
        margin-bottom: 4px;
        font-size: 14px;
      }
    }

    p {
      @media screen and (max-width: $screen-xs) {
        margin-bottom: 0;
        color: #aeaeae;
        font-size: 11px;
      }
    }
  }
}

// ********************************************   Modal Scss   ********************************************
.modal {
  &.filter-popup {
    .modal-dialog {
      .modal-content {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: none;
        border: none;

        .modal-header {
          padding: 12px 18px;
          border-bottom: 1px solid $light-black;

          a.btn-style {
            color: $light-black;
            padding: 0;
            height: auto;
            font-weight: 600;
            font-size: 12.0833px;
            line-height: 16px;
          }

          a.close-popup {
            display: flex;
            align-items: center;
            gap: 7px;
          }
        }

        .modal-body {
          padding: 0;
          .certificate-body-wrapper {
            .img-wrapper {
              padding: 20px 80px;
              img {
                width: 100%;
                max-height: 400px;
              }
            }
          }
          .filter-body-wrapper {
            .filter-tabs-wrapper {
              display: grid;
              grid-template-columns: 118px auto;
              column-gap: 14px;
              min-height: 200px;

              .nav-pills {
                background: #e4e4e4;
                padding: 14px 0;

                .nav-item {
                  .nav-link {
                    background: transparent;
                    border: 0.2px solid transparent;
                    border-radius: 0;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    color: $black;

                    &.active {
                      background: #f5f5f5;
                      border: 0.2px solid transparent;
                    }
                  }
                }
              }

              .tab-content {
                padding: 14px 14px 14px 0;

                .form-group {
                  &.checkbox {
                    margin-bottom: 7px;

                    input {
                      display: none;
                    }

                    label {
                      position: relative;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 19px;
                      color: $light-black;

                      &:before {
                        content: "";
                        -webkit-appearance: none;
                        width: 15px;
                        height: 15px;
                        display: inline-block;
                        position: relative;
                        vertical-align: middle;
                        cursor: pointer;
                        margin-right: 9px;
                        background: #ffffff;
                        border: 0.6px solid #333333;
                        border-radius: 2px;
                      }
                    }

                    input:checked + label:after {
                      content: "";
                      display: block;
                      position: absolute;
                      top: 5px;
                      left: 5px;
                      width: 5px;
                      height: 11px;
                      border: solid $primary01;
                      border-width: 0 2px 2px 0;
                      transform: rotate(45deg);
                    }
                  }

                  &.date {
                    &:not(:last-of-type) {
                      margin-bottom: 22px;
                    }

                    label {
                      display: block;
                      font-weight: 400;
                      font-size: 11px;
                      line-height: 15px;
                      color: $light-black;
                      margin-bottom: 4px;
                    }

                    input[type="date"] {
                      border: 1px solid rgba(0, 0, 0, 0.3);
                      box-shadow: 0 1.68641px 1.68641px rgba(0, 0, 0, 0.25);
                      border-radius: 3px;
                      padding: 5px 14px;
                    }
                  }
                }
                .form {
                }
              }
            }
          }
        }

        .modal-footer {
          background: #f5f5f5;
          justify-content: center;
          padding: 19px 20px 16px;
          border: 0;

          a.btn-style {
            box-shadow: $btn-shadow;
          }
        }
      }
    }
  }

  &.sort-popup {
    .modal-content {
      .modal-header {
      }

      .modal-body {
        padding: 30px 30px;

        .sort-body-wrapper {
          .sort-options {
            max-width: 225px;
            margin: auto;

            a.btn-style {
              height: 45px;

              &:not(:last-of-type) {
                margin-bottom: 24px;
              }

              &.active {
                background: $primary01;
                color: $white;
              }
            }
          }
        }
      }

      .modal-footer {
      }
    }
  }

  &.scratch-popup {
    background: rgba($light-black, 0.9);

    .modal-content {
      background: transparent !important;

      .modal-header {
        border: none;
        justify-content: flex-end;
        padding-bottom: 0;
      }

      .modal-body {
        padding: 30px 30px;
        text-align: center;

        .scratch-body-wrapper {
          margin: 43px auto 34px auto;
          max-width: 235px;
          height: 235px;

          .scratch-img-wrapper {
            max-width: 235px;
            height: 235px;
            margin: auto;

            .ScratchCard__Canvas {
              left: 0;
            }

            img {
              width: 100%;
            }
          }
        }

        p {
          font-size: 24px;
          line-height: 32px;
          color: white;
          font-weight: 600;
        }

        .btn-style {
          margin-top: 27px;
        }
      }

      .modal-footer {
      }
    }
  }

  .modal-dialog {
    .modal-content {
      .modal-header {
        padding: 12px 18px;
        border-bottom: 1px solid $light-black;

        a.btn-style {
          color: $light-black;
          padding: 0;
          height: auto;
          font-weight: 600;
          font-size: 12.0833px;
          line-height: 16px;
        }

        a.close-popup {
          display: flex;
          align-items: center;
          gap: 7px;
        }
      }

      .modal-body {
        .sort-body-wrapper {
          .sort-options {
            max-width: 225px;
            margin: auto;

            a.btn-style {
              height: 45px;
              margin-bottom: 24px;

              &.active {
                background: $primary01;
                color: $white;
              }
            }
          }
        }
      }

      .modal-footer {
      }
    }
  }

  &.invoice-popup {
    .modal-content {
      max-width: 280px;
      margin: auto;

      .modal-header {
        border: none;
        justify-content: center;

        h3 {
          font-weight: 600;
          font-size: 20px;
          line-height: 27px;
        }
      }

      .modal-body {
        .invoice-body-wrapper {
          .file-upload {
            width: 182px;
            height: 93px;
            border: 1px dashed $black;
            border-radius: 8px;
            background: $white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: auto auto 23px auto;

            img {
              margin: 0 0 8px 0;
              height: 18px;
              width: 18px;
            }

            input[type="file"] {
              display: none;
            }

            p {
              font-weight: 400;
              font-size: 8px;
              line-height: 11px;
              margin-top: 3px;

              span {
                color: $primary01;
              }
            }
          }

          .btn-style {
            min-width: 127px;
            padding: 8px 12px 10px;
          }
        }
      }
    }
  }

  &.success-popup {
    .modal-content {
      max-width: 256px;
      margin: auto;

      .modal-header {
        border: none;
        justify-content: flex-end;
        padding-bottom: 0;
      }

      .modal-body {
        padding: 0 15px 22px 15px;

        .success-body-wrapper {
          img {
            margin-bottom: 19px;
          }

          h3 {
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: -0.165px;
            color: $black;
          }

          p {
            margin-top: 7px;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.165px;
            color: $black;
          }
        }
      }
    }
  }

  &.transaction-popup {
    .modal-content {
      max-width: 310px;
      margin: auto;

      .modal-header {
        border: none;
        justify-content: flex-end;
        padding-bottom: 0;
      }

      .modal-body {
        padding: 0 15px 22px 15px;

        .transactions-details-wrapper {
          h2 {
            text-align: center;
            margin-bottom: 31px;
          }

          p {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: -0.01em;
            color: $light-black;
            margin-bottom: 7px;
          }

          p.blue {
            font-weight: 700;
            color: $primary02;
          }

          .btn-wrapper {
            margin-top: 27px;

            .btn-style {
              min-width: 56px;
              height: 34px;
              padding: 5px;
            }
          }
        }
      }
    }
  }

  &.redeem-success-popup {
    .modal-content {
      max-width: 340px;
      margin: auto;

      .modal-header {
        border: none;
        justify-content: flex-end;
        padding-bottom: 0;
      }

      .modal-body {
        padding: 0 27px 17px 27px;

        .success-body-wrapper {
          .img-wrapper {
            width: 70px;
            height: 70px;
            margin: 0 auto 17px auto;

            img {
              width: 100%;
            }
          }

          h2 {
            font-size: 24px;
            line-height: 31px;
            color: $black;
            margin-bottom: 50px;
            text-align: center;
          }

          h3 {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
          }

          .btn-style {
            margin: 23px auto 32px auto;
            min-width: 100%;
            height: 40px;
            color: white;

            &:hover {
              color: $primary01;
              background: transparent;
            }
          }
        }

        .share-wrapper {
          display: none;
          background: rgba($light-black, 0.9);
          width: 330px;
          height: 97px;
          margin: auto;
          position: absolute;
          top: -35px;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 99;
          padding: 12px 34px;

          &:after {
            content: "";
            position: absolute;
            width: 60px;
            height: 26px;
            background: rgba($light-black, 0.9);
            left: 116px;
            bottom: -25px;
            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
            transform: rotate(-180deg);
          }

          &.active {
            display: block;
          }

          h3 {
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            color: $white;
            margin-bottom: 10px;
          }

          ul {
            li {
              margin-right: 19px;

              a {
                display: block;

                img {
                  width: 36px;
                  height: 36px;
                }
              }
            }
          }
        }
      }

      .modal-footer {
        display: none;
      }
    }
  }

  &.condition-popup {
    .modal-content {
      max-width: 332px;
      margin: auto;

      .modal-header {
        flex-direction: column;
        padding: 12px 15px 22px 15px;

        .top-header,
        .bottom-header {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;

          h3 {
            font-size: 16px;
            line-height: 21px;
            font-weight: 700;
          }

          label {
            p {
              font-size: 12px;
              line-height: 16px;
              font-weight: 600;
              color: rgba($light-black, 0.8);

              span {
                color: $primary01;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
        }
      }

      .modal-body {
        padding: 0 15px 22px 15px;

        .condition-body-wrapper {
          margin-top: 24px;

          .body-top {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            label {
              p {
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
                color: rgba($light-black, 0.8);

                img {
                  width: 16px;
                  height: 16px;
                  margin-left: 15px;
                }
              }
            }
          }

          .form {
            margin-top: 24px;

            .form-group {
              margin-bottom: 32px;
              max-width: 255px;

              .form-control.pen-icon {
                height: 40px;
                color: $black;
                border: 1px solid $gray;
                background: #f8f8f8 url("../images/icons/edit-icon.svg")
                  no-repeat calc(100% - 10px);
              }
            }
          }
        }

        .btn-wrapper {
          .btn-style {
            min-width: 225px;
            height: 40px;
            padding: 5px;
          }
        }
      }

      input[type="checkbox"] {
        width: 16px;
        height: 16px;
      }
    }
  }

  &.account-issues-popup {
    .modal-content {
      max-width: 320px;
      margin: auto;

      .modal-header {
        border: none;
        justify-content: flex-end;
        padding-bottom: 0;
      }

      .modal-body {
        padding: 0 15px 22px 15px;

        .account-body-wrapper {
          img {
            width: 60px;
            height: 60px;
            margin: 10px auto 14px auto;
          }

          p {
            font-size: 16px;
            line-height: 21px;
            color: $black;

            span {
              font-size: 16px;
              line-height: 21px;
              font-weight: 600;
              color: $primary01;
            }
          }
        }
      }
    }
  }
}

.confetti-visibility {
  display: none;

  &.active {
    display: block;
  }

  canvas {
    z-index: 99999 !important;
    position: absolute !important;
    height: 100% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.offer {
  background-image: url("../images/product-offering-img-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 40px;
  height: 40px;
  margin: 0 auto 10px;

  color: white;
  font-size: 10px;
  line-height: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-bank-title {
  text-align: center;
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #6c7ac9;
  color: #6c7ac9;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  background: #6c7ac9;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}
